import { template as template_9147de7a26a9433b8a37ce84ddc31bbd } from "@ember/template-compiler";
const FKControlMenuContainer = template_9147de7a26a9433b8a37ce84ddc31bbd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
